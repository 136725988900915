.add-participant-label {
  color: #8a73a5;
  display: block;
  font-family: "Muli Bold", sans-serif;
  font-size: 1.2rem;
}

.add-participant-input {
  border-bottom: 0.5px solid #000000;
  color: #303030;
  display: block;
  font-family: "Muli Light", sans-serif;
  font-size: 1rem;
  line-height: 25px;
  margin: 0 auto 20px;
  outline: none;
  text-align: center;
  width: 100%;
}

.add-participant-select-arrow {
  border-bottom: 0.5px solid #000000;
  cursor: pointer;
  display: flex;
  margin: 15px auto;
  width: 100%;

  &:after {
    content: "";
    left: 75%;
    padding: 9px 0 0 0;
    pointer-events: none;
    position: absolute;
    transform: rotate(45deg);
    width: 12px;
    border: solid #2699fb;
    border-width: 0 3px 3px 0;
  }

  .add-participant-select {
    color: #303030;
    font-family: "Muli Light", sans-serif;
    font-size: 1rem;
    padding-bottom: 10px;
    text-align: center;
    appearance: none;
    position: relative;
    text-align-last: center;
    vertical-align: middle;
    width: 100%;
    outline: none;
  }
}

.add-participant-add-button {
  background: #87BF40;
  color: #fff;
  font-size: 1rem;
  margin: 0 auto 30px;
  padding: 5px 0;
  width: 80%;
  &:disabled {
    background: rgba($color: #000000, $alpha: .4);
    cursor: not-allowed;
  }
}

.swal2-close {
  background-color: #e81e46d7 !important;
  transition: .2s !important;
  right: .5rem !important;
  top: .5rem !important;
  height: 27px !important;
  width: 27px !important;
  &:hover {
    border: 1px solid #e81e46d7 !important;
  }
}