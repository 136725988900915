@import "../../assets/styles/abstracts/variables", "../../assets/styles/abstracts/mediaqueries";

.loading {  
  .text-loading {
    color: $orange-title;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 25px;
  }

  .spinner-border {
    width: 5rem !important;
    height: 5rem !important;
    border: .30rem solid $orange-title !important;
    border-right-color: transparent !important;
    margin-top: 50px;
  }
}