@import "../../assets/styles/abstracts/variables";

.login {
  align-items: center;
  background: $content-bg;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 1030;
  top: 0;
  left: 0;
}

// Card Content
.login-content {
  background: $white-bg;
  border-radius: 30px;
  box-shadow: 0 3px 6px #00000029;
  padding: 50px;
  width: 50%;

  @include sm {
    width: 70%;
  }

  @include xs {
    width: 90%;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 250px;
  }

  // Title
  .login-title {
    color: $page-title;
    font-family: "DK Aderyn", sans-serif;
    font-size: 28px;
    margin: 10px 0 30px;
    text-align: center;
  }
}

// Form
.login-form {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.login-input,
.login-label {
  display: block;
}

// Input
.login-input {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  color: $input-text;
  font-family: "Muli Light", sans-serif;
  font-size: 14px;
  line-height: 25px;
  margin: 0 auto;
  outline: none;
  text-align: center;
  width: 80%;

  &:focus {
    border-bottom: 0.5px solid $orange-bg;
  }
}

// Label
.login-label {
  color: $orange-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
  margin: 15px 0;
  text-transform: uppercase;
}

// Button
.login-button {
  background: $green-bg;
  border-radius: 30px;
  color: $white-text;
  font-family: "Muli Bold", sans-serif;
  font-size: 18px;
  margin-top: 20px;
  padding: 7px 0;
  text-transform: uppercase;
  width: 100%;
}
