@import "../../assets/styles/abstracts/variables", "../../assets/styles/abstracts/mediaqueries";

// Card da Oficina
.workshop-item {
  display: inline-block;
  margin: 20px;
  position: relative;
  text-align: center;
  width: 250px;

  @include md {
    margin: 1.5%;
    width: 30%;
  }

  @include sm {
    margin: 1%;
    width: 48%;
  }

  @include xs {
    margin: 20px 0;
    width: 100%;
  }
}

// Imagem
.workshop-image {
  width: 150px;
}

// Title
.workshop-title {
  color: $orange-title;
  font-family: "Muli Bold", sans-serif;
  font-size: 1.1em;
  margin: 15px 0 0;
  text-transform: uppercase;
}

// Button Icon
.workshop-button {
  align-items: center;
  background: $white-bg;
  border-radius: 50%;
  box-shadow: 0 3px 10px rgba(0,0,0,.2);
  display: flex;
  height: 30px;
  justify-content: center;
  position: absolute;
  top: 15px;
  width: 30px;
  
  &.edit{
    border: 1px solid $warning-button;
    color: $warning-button;
    right: 15px;

    &:hover{
      background: $warning-button;
      color: white;
    }

  }

  &.remove{
    border: 1px solid $red-button;
    color: $red-button;
    left: 15px;

    &:hover{
      background: $red-button;
      color: white;
    }
  }
}

// Sweet Alert
.confirm-button-class,
.cancel-button-class {
  margin: 0.3125em;
  min-width: 140px;
}

.confirm-button-class {
  border: 2px solid $red-button;
  color: $red-button !important;
  padding: 0.625em 2em;
}

.cancel-button-class {
    background: $red-button;
    color: $white-text;
    padding: calc(0.625em + 2px) 2em;
}