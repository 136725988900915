@import "../../assets/styles/abstracts/variables";

.pagination-with-search-container {
    display: flex;
    flex-direction: column;
}
.row.pagination-searchbox-container {
    padding: 0 .5rem 0 .5rem;
}
.pagination-searchbox {
    min-width: 20rem;
}
.pagination-searchbox-container .button-container{
    display: flex;
    align-items: center;
}
.page-item {
    background-color: $white-bg;
    border-color: #dee2e6;
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 0;
    color: $orange-text;
    width: 2.5rem;
    height: 2.5rem;
    transition: 400ms all;
}

.page-item:disabled {
    opacity: .5;
}
.page-item:last-child {
    border-right-width: 1px;
}

.page-item-active, .page-item:not(:disabled):hover {
    color: $white-text;
    background-color: $green-button;
    border-color: $green-button;
}

.pagination-button-container {
    display: flex;
    justify-content: center;
    margin: 1rem 0 1rem 0;
}

.page-item-dots {
    display: flex;
    align-items: center;
    color: $poder-text;
}