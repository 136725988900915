.non-existent-list-notice p {
    color: #303030;
    margin-top: 2rem;
}

.edit-presence-list-content {
    overflow-y: scroll;
    max-height: 300px;
}

.student-name {
    max-width: 20ch !important;
}

.list:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05) !important;
}