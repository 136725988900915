.swal2-container{
    font-family: 'Muli Bold', sans-serif;

    .swal2-icon{
        margin: 0;
    }

    button{
        color: #ffffff;
        border-radius: 30px !important;
        font-family: 'Muli Bold', sans-serif;
        font-size: 14px;

        a{
            color: #ffffff;
        }
    }
}

// Title
.swal2-title{
    color: #F69522 !important;
    margin: 0 !important;

    p{
        font-size: 17px;
    }
}

// Text
.swal2-content{
    color: #F69522 !important;
}