.button-full {
  border: 1px solid;
  border-radius: 30px;
  cursor: pointer;
  font-family: "Muli Bold", sans-serif;
  font-size: 16px;
  padding: 7px 0;
  width: 200px;
  text-align: center;
  text-transform: uppercase;
}

// Botão Adicionar
.btn-create {
  border-color: $green-bg;
  color: $green-bg;
  transition: 400ms all;

  &:hover {
    background-color: $green-bg;
    color: $white-text;
  }
}

// Botão Salvar/Cadastrar
.btn-save {
  background-color: $green-bg;
  color: $white-text;
  transition: 400ms all;

  &:hover {
    background-color: transparent;
    border-color: $green-bg;
    color: $green-bg;
  }
}

.m-right {
  margin-right: 10px;
}

// Botão de Edição
.btn-edit {
  border: 1px solid $warning-button;
  border-radius: 30px;
  color: $black-text;
  font-family: "Muli Semibold", sans-serif;
  font-size: 12px;
  padding: 7px 20px;
  text-transform: uppercase;
  transition: 400ms all;

  &:hover {
    background-color: $warning-button;
    color: $black-text;
  }
}

// Botão de Remoção
.btn-remove {
  border: 1px solid $red-button;
  border-radius: 30px;
  color: $black-text;
  font-family: "Muli Semibold", sans-serif;
  font-size: 12px;
  padding: 7px 20px;
  text-transform: uppercase;
  transition: 400ms all;
  cursor: pointer;

  &:hover {
    background-color: $red-button;
    color: $white-text;
  }
}

// Botão File
.btn-file {
  border-color: $file-button;
  color: $value-text !important;
  padding: 7px 20px;
  transition: 400ms all;

  &:hover {
    background-color: $file-button;
    color: $white-text !important;
  }
}

// Botão Exportar
.btn-export {
  border-color: $file-button;
  color: $value-text;
  transition: 400ms all;
  margin: 0px 3px;

  &:hover {
    background-color: $file-button;
    color: $white-text !important;
  }
}