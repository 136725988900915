.pagination-content{
    display: block;
    margin: 0 auto;
}

.action-btn td {
    display: flex;
    flex-direction: column;
}

.action-btn td a {
    text-align: center;
    width: 180px;
    padding: 4px;
    margin: 0;
}

.edit-presence-list-content,
.edit-participants-content,
.edit-workshop-content {
    padding: .5rem 0;
    margin-bottom: -.5rem;
    position: relative;

    h3 {
        margin: .5rem 0 1rem 0;
    }

    ul {
        overflow-y: scroll;
        max-height: 300px;
      }

    li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed rgba($color: #000, $alpha: 0.2);
        padding: .5rem 0;

        label {
            max-width: 30ch;
            text-align: left;
            color: #303030;
            margin: 0;
            padding: .5rem 0;
        }

        a {
            font-size: 1rem;
            color: #fff;
            padding: .5rem;
            border-radius: 10px;
            transition: .5s;
        }

        a:hover {
            opacity: 0.6;
        }

        select {
            border: 1px solid rgba($color: #303030, $alpha: .2);
            border-radius: 5px;
            color: #3e68b2;
            padding: .5rem;
        }
    }    
}

.confirm-button-class {
    background-color: #87BF40;
    border-color: #87BF40;
    color: #fff !important
}

.cancel-button-class {
    background-color: #fff;
    color: #87BF40 !important;
    border: 2px solid #87BF40;
    height: 42.5px;
}

.arrow-back {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    cursor: pointer;
}

// Input Radio
.presenca-input-radio {
	box-sizing: border-box;
	display: inline-block;
    justify-content: space-between;
    width: 140px;

	input {
        height: 0;
        left: -9999px;
        position: absolute;
        width: 0;
        .presence {
            background-color: #87BF40;
            border-color: #87BF40;
        }
    }

	input + label {
        background-color: #FFF;
        border: 1px solid #3E68B2;
        border-radius: 5px;
        box-sizing: border-box;
        color: #3E68B2;
        cursor: pointer;
        display: inline-block;
        font-family: 'Muli', sans-serif;
        font-size: 12px;
        margin: 0;
        padding: 4px;
        position: relative;
        text-align: center;
        width: 65px;

        &:last-child{
            margin-left: 10px;
        }
    }

	input:checked + label {
		color: #FFF;
        z-index: 1;

        &.falta{
            background-color: #E81E47;
            border-color: #E81E47;
        }

        &.presenca{
            background-color: #87BF40;
            border-color: #87BF40;
        }
	}
}

.pagination{
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    justify-content: center;

    li{
        display: list-item;
        
        a{
            position: relative;
            display: block;
            padding: .5rem .75rem;
            margin-left: -1px;
            line-height: 1.25;
            color: #F69522;
            background-color: #fff;
            border: 1px solid #dee2e6;

            &:hover {
                z-index: 2;
                text-decoration: none;
                background-color: #e9ecef;
                border-color: #dee2e6;
            }

        }
        
        &.disabled {
            a{
                cursor: default;
                background-color: #fff;
            }
        }

        &.active {
            a{
                background: #87BF40;
                color: #ffffff;
            }
        }
    }
}
.modal-backdrop {
    z-index: 3 !important;
  }

  .button-full {
    width: auto;
    padding: 7px 20px;
    margin: 0px 6px;
  }

  .button-w-100 {
    min-width: 100% !important;
  }
  .red {
      color: #E81E47;
  }

  .container-btn {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
  }

  .fa-arrow-left {
      color: #303030;
      position: absolute;
      left: -100px;
      top: 0.5rem;
      cursor: pointer;
  }
  