@import '../../assets/styles/base/global';

$border-color: rgba(222, 222, 222, 0.1);

body and .overlay{
    overflow: hidden
}

section{
    $over: initial;
    background: $content-bg;
    padding-left: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width:  100%;
    z-index: -1;
    overflow: $over;
}

a{
    text-decoration: none !important;
}

.nav-margin{
    padding-top: 56px;
}

.navbar-ifa{
    background: $white-bg;
    color: $orange-bg;
    border-bottom: 1px solid #00000029;
    //@include shadow-bottom($orange-bg);
    button{
        color: $orange-bg;
        &:focus{
            text-decoration: none;
            outline: none;
        }
    }

    .navbar-brand{
        width: 66%;
        .w{
            width: 35px;
        }
        .h{
            width: 90px;
        }
    }

    .navmenu{
        width: 100%;
    }

    .mg-t-10{
        margin-top: 10px;
    }

}

#overlay{
    position: fixed;
    display: none;
    opacity: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    overflow: hidden;
    animation: showup .35s;
    @keyframes showup {
        0%{display: none; opacity: 0}
        1%{display: block}
        100%{opacity: 1}
    }
    @keyframes dismiss {
        0%{opacity: 1}
        99%{opacity: 0}
        100%{display: none}
    }
}

#sidemenu{
    position: absolute;
    z-index: 5;
    top: 56px;
    left: 0;
    width: 256px;
    margin-left: -256px;
    height: 100vh;
    background: $white-bg;
    color: $orange-bg;
    transition: margin .35s ease;

    .nav-btn{
        color: $orange-bg;
        display: block;
        width: 100%;
        padding: 0.9rem;
        font-size: 1.2em;
        padding-left: 48px;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-radius: 0.3rem;
        text-align: left;
        white-space: nowrap;
        transition: .1s;
        label{ margin: 0 };
        &:focus{
            outline: none;
            background: $red-button;
            transition: .1s;
        }
        &.borderless{
            border: 0;
        }
        &.borderless-b{
            border-bottom: 0;
        }
        &.logout{
            position: absolute;
            bottom: 57px;
            max-width: 100%;
        }
        &.sub-item{
            height: 2.3rem;
            padding-top: 5px;
            label{
                font-size: 15px;
            }
            i{
                font-size: 19px;
                margin-top: 0;
            }
        }
    }
}

#sidebar{
    $sidebar-open: 5rem;
    @include xs{
        display: none;
    }
    @include sm{
        display: none;
    }
    position: fixed;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 3;
    top: 0;
    left: 0;
    width: $sidebar-open;
    height: 100%;
    max-height: 100%;
    // background: linear-gradient(to bottom left, $orange-bg, lighten($orange-bg, 15%));
    background: $white-bg;
    transition: 0.3s;
    overflow-x: hidden;
    border-right: 1px solid #00000029;
    .sidebar-item-wrappers{
        width: 100%;
        line-height: 1px;
        overflow-x: hidden;
        overflow-y: auto;
        white-space: nowrap;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    &:hover{
        $sidebar-open: 216px;
        width: $sidebar-open; 
        .menu-item{
            min-height: 3rem;
            label{
                opacity: 1;
                cursor: pointer;
                margin-left: 24px;
                display: inline-block;
                // transition: .8s;
            }
        }
        .menu-logo{
            .h{
                display: inline-block;
                margin-left: 16px;
                opacity: 1;
                transition: .8s
            }
        }
        .sub-item{
            min-height: 2.3rem
        }
    }
}

.menu-logo{
    width: 100%;
    padding: 15px 0 15px 16px;
    vertical-align: middle;
    white-space: nowrap;
    border-bottom: 1px solid rgba(222, 222, 222, 0.1);    
        .w{
            width: 42px;
        }
        .h{
            opacity: 0;
            height: 27px;
    }
}

.collapse{
    width: 100%;
}

.menu-item{
    display: flex;
    justify-content: flex-start;
    padding: 15px 25px;
    min-width: 100%;
    width: 100%;
    min-height: 3rem;
    transition: .1s;
    color: $orange-bg;
    line-height: calc(3rem - 20px);
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    &:hover{
        cursor: pointer;
        background: $green-bg;
        // transition: .15s;
        color: $white-bg;

        i{
            color: $white-bg;
        }
    }
    &:active, &:focus{
        background: $green-bg;
        transition: .15s;
        outline: none;
    }
    &.item-border{
        border-top: 1px solid rgba(222, 222, 222, 0.1);
    }
    &-has-items{
        &:active, &:focus, &.focused{
            background: rgba(0,0,0,0.2);
            transition: .15s;
            outline: none;
        }
    }
    .icon{
        min-width: 25px;
        text-align: center;
    }
    i{
        color: $orange-bg;
        margin-top: .2rem;
        font-size: 22px;
        
    }
    label{
        position: relative;
        width: 100%;
        opacity: 0;
        font-size: 16px;
        margin-bottom: 0 !important;
        margin: 0;
        .fa-caret-down{
            position: absolute;
            font-size: 15px;
            right: 0;
            top: 4px;
        }
    }
    &.sub-item{
        min-height: 2.3rem;
        line-height: calc(2.3rem - 20px);
        margin-bottom: 0;
        margin-top: 0;
        transition: .2s;
        label{
            font-size: 15px;
        }
        i{
            font-size: 19px;
            margin-top: 0;
        }
    }

}
.full-width{
    width: 100%;
}

a[_ngcontent-c1]:hover {
    color: $disabled-button
}

[aria-expanded=true] .fa-caret-down{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: .30s;
}

[aria-expanded=false] .fa-caret-down{
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .30s;
}

.focused{
    background: rgb(60, 181, 138);
    transition: .15s;
    outline: none;
}
