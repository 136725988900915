@import "../abstracts/variables";

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

.row {
  &.row-center {
    align-items: center;
  }
}

.table td {
  border-top: none !important;
  padding: 0.35rem !important;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-btn .td {
  display: none;
}

// Form
.form-group {
  position: relative;
}

// Responsive
.hidden-xs-down {
  @media (min-width:#{1px}) and (max-width:#{$xs-width - 1}) {
    display: none;
  }
}
.hidden-xs-up {
  @media (min-width:#{$xs-width}) and (max-width: #{20000px}) {
    display: none;
  }
}
.hidden-sm-down {
  @media (min-width:#{1px}) and (max-width:#{$sm-width - 1}) {
    display: none;
  }
}
.hidden-sm-up {
  @media (min-width:#{$sm-width}) and (max-width: #{20000px}) {
    display: none;
  }
}
.hidden-md-down {
  @media (min-width:#{1px}) and (max-width:#{$md-width - 1}) {
    display: none;
  }
}
.hidden-md-up {
  @media (min-width:#{$md-width}) and (max-width: #{20000px}) {
    display: none;
  }
}
.hidden-lg-down {
  @media (min-width:#{1px}) and (max-width:#{$lg-width - 1}) {
    display: none;
  }
}
.hidden-lg-up {
  @media (min-width:#{$lg-width}) and (max-width: #{20000px}) {
    display: none;
  }
}
.hidden-xl-down {
  @media (min-width:#{1px}) and (max-width:#{$xl-width - 1}) {
    display: none;
  }
}
.hidden-xl-up {
  @media (min-width:#{$xl-width}) and (max-width: #{20000px}) {
    display: none;
  }
}

//Card content
.content-card {
  font-size: 16px;
  color: $black-text;
  background: $white-bg;
  padding: 2rem 2rem 4rem 2rem;
  margin: 1rem 1rem 2rem 6rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  @include xs {
    padding: 1rem 1rem 1rem 1rem;
    margin: 1.5rem;
    .text-form-input {
      text-align: center;
    }
  }
  @include sm {
    padding: 1rem 1rem 1rem 1rem;
    margin: 1rem;
    .text-form-input {
      text-align: center;
    }
  }
  @include md {
    padding: 1rem 2rem 2rem 2rem;
    margin: 1rem 1rem 2rem 6rem;
  }
}

.centered-title {
  font-family: "DK Aderyn", sans-serif;

  @include xs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    h1 {
      margin: 0;
      font-size: 1.8rem;
    }
  }
  @include sm {
    display: flex;
    justify-content: space-around;
    align-items: center;
    h1 {
      margin: 0;
    }
  }
}

.actions {
  text-align: center;
}