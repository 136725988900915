@import '../../../assets/styles/abstracts/variables';

// Sweet Alert
.confirm-button-class,
.cancel-button-class {
  margin: 0.3125em;
  min-width: 140px;
}

.confirm-button-class {
  border: 2px solid $red-button;
  color: $red-button !important;
  padding: 0.625em 2em;
}

.cancel-button-class {
    background: $red-button;
    color: $white-text;
    padding: calc(0.625em + 2px) 2em;
}

.power-upload-image{
  color: $white-text;
  text-transform: uppercase;
}