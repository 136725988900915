@import "../abstracts/variables";

// Título da página
.page-title {
  color: $orange-title;
  font-family: "DK Aderyn", sans-serif;
  letter-spacing: 1px;
  padding: 0 15px;

  h1 {
    font-size: 36px;

    @include xs {
      margin: 0 auto;
    }
  }
}

// Título da Lista com botão
.content-header {
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 15px;

  @include xs {
    justify-content: center;
  }

  // Título da Lista
  .list-title {
    color: $list-title;
    display: inline-block;
    font-family: "Muli Bold", sans-serif;
    font-size: 20px;

    @include xs {
      display: block;
      text-align: center;
      width: 100%;
    }
  }
}

// Título do form
.form-title {
  color: $list-title;
  font-family: "Muli Bold", sans-serif;
  font-size: 20px;
}
