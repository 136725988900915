.row {
  display: flex;
  justify-content: space-between;
  row-gap: 1rem;
}

select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.45rem 1rem !important;
  border-radius: 5px;
  font-family: "Segoe UI";
  color: #212529;
  width: 14rem;
  height: 2.38rem;
}

input[type="date"],
select {
  cursor: pointer;
}

.list-title {
  color: #303030;
  display: inline-block;
  font-family: "Muli Bold", sans-serif;
  font-size: 1rem;
}

.counter-container,
.status-container,
.search-container-input {
  display: flex;
  flex-direction: column;
}

.mt-1rem {
  margin-top: 1rem;
}

.wt-9rem {
  width: 9rem !important;
}

.wt-14rem {
  width: 14rem !important;
}

.wt-22rem {
  width: 22rem !important;
}

.btn-edit {
  background-color: #87BF40;
  border: 1px solid #87BF40;
  color: #fff;
  width: max-content;
  padding: 0.25rem 1rem;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background: none !important;
    color: #87BF40;

  }
}

#swal2-content {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}