.box-card{
  background: #ffffff;
  border-radius: 9px;
  box-shadow: 0 3px 6px #00000029;
  padding: 15px 20px;
}

.box-card-fullscreen{
  width: 100vw;
  max-width: 85vw;
  border-radius: 9px;
  box-shadow: 0 3px 6px #00000029;
  padding: 1.5rem;
  overflow: hidden !important;
}

.box-card-fullscreen:last-child {
  margin-bottom: 1rem;
}