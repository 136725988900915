.form-check {
    display: flex !important;
}

.new-alternative{
    vertical-align: middle;
    font-size: 1em;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    color: #28a745 !important;
    margin: 0 5px;
}

.remove-alternative{
    color: red;
    margin: 7px;
    cursor: pointer;
}
.separator {
    border: solid 1px #ccc
}