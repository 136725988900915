.edit-stars-content {
  padding: 0.5rem 0;
  margin-bottom: -0.5rem;
  position: relative;

  ul {
    overflow-y: scroll;
    max-height: 300px;
  }

  .student-name {
    max-width: 20ch !important;
  }

  .stars-list-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed rgba($color: #000, $alpha: 0.2);
  }

  div:first-child {
    padding: 0.5rem 0 0 0;
  }

  p {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    color: #f69522;
  }

  .no-power {
    color: rgba(0, 0, 0, 0.5);
  }

  h3 {
    margin: 0 0 1rem 0;
  }

  li {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1rem;
    height: fit-content;

    label {
      max-width: 30ch;
      text-align: left;
      color: #303030;
      margin: 0;
      padding: 0.5rem 0;
    }

    a {
      font-size: 1rem;
      color: #fff;
      padding: 0.5rem;
      border-radius: 10px;
      transition: 0.5s;
    }

    a:hover {
      opacity: 0.6;
    }
  }

  select {
    border: 1px solid rgba($color: #303030, $alpha: 0.2);
    border-radius: 5px;
    color: #3e68b2;
    padding: 0.5rem;
    height: fit-content;
    font-size: 0.9rem;
  }
}

.list:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
