.action-icons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .editPresenceList {
        background-color: #87BF40;
    }

    .attributePresenceList {
        background-color: #5E862D;
    }

    .powers {
        background-color: #3E68B2;
    }

    .missions {
        background-color: #F69522;
    }

    .stars {
        background-color: #C7BC1E;
    }
}

.tooltip-text {
   margin: 0.2rem;
}