.modal {
  background: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100vh;
  left: 0;
  padding: 40px 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.modal-content {
  background: #ffffff;
  border-radius: 7px;
  max-height: 100%;
  overflow: auto;
  padding: 20px 25px;
}

// Button
.modal-close-button{
  border: 2px solid#87BF40;
  border-radius: 30px;
  color: #87BF40;
  cursor: pointer;
  display: block;
  margin: 10px auto 0;
  text-transform: uppercase;
  width: 180px;
}