.participants-remove {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 27px;
  width: 27px;
}

.participants-remove-disabled {
  border-radius: 50%;
  cursor: not-allowed;
  display: inline-block;
  height: 27px;
  width: 27px;
  opacity: 0.5;
}

.exclude-all-participants-warning {
  color: #e81e47;
  -moz-animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation: cssAnimation 0s ease-in 5s forwards;
  -o-animation: cssAnimation 0s ease-in 5s forwards;
  animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.edit-participants-content span {
  color: #303030;
  padding: 0.5rem 1.5rem;
  margin: 1rem 0;
}

.add-user {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 27px;
  width: 27px;
}

.add-participant {
  color: #87bf40;
  margin: 1.5rem 0 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    cursor: pointer;
    color: #87bf40;
    &:hover {
      color: #87bf40;
    }
    img {
      margin-left: 0.5rem;
    }
  }
}

.swal2-html-container {
  color: #303030;
  margin-top: 1.5rem;
}

.swal2-confirm.swal2-styled {
  border: 2px solid #e81e47;
}

.swal2-cancel.swal2-styled {
  border: 2px solid #303030 !important;
  color: #303030 !important;
}

.add-participant-title {
  color: #303030;
  font-family: "Muli Bold", sans-serif;
  font-size: 1.4rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.add-participant-label {
  color: #8a73a5;
  display: block;
  font-family: "Muli Bold", sans-serif;
  font-size: 1.2rem;
}

.add-participant-label-title {
  color: #8a73a5;
  display: block;
  font-family: "Muli Bold", sans-serif;
  font-size: 1.4rem;
}

.add-participant-participants-list {
  max-height: 15rem;
  overflow-y: scroll;
  li {
    align-items: center;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  // Nome e Data de nascimento
  .participants-info {
    display: inline-block;
  }

  .participants-name,
  .participants-date {
    display: block;
    font-family: "Muli", sans-serif;
    text-align: left;
  }

  //$participants-name-width-mobile: 20ch;

  .participants-name {
    color: #3e68b2;
    font-size: 1.125rem;
    max-width: 30ch;
    @media (max-width: 500px) {
      max-width: 23ch;
      font-size: 1rem;
    }
  }

  .participants-date {
    color: #303030b9;
    font-size: 1rem;
    margin-bottom: 5px;
  }

  // Botões
  .participants-buttons {
    display: inline-block;
  }

  .participants-edit,
  .participants-remove {
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 27px;
    width: 27px;
  }

  .participants-remove {
    margin-left: 15px;
  }
}
