// DK
@font-face {
    font-family: 'DK Aderyn';
    src: url('../../fonts/DK/DK-Aderyn.otf');
}

@font-face {
    font-family: 'DK Lemon';
    src: url('../../fonts/DK/DK-Lemon-Yellow-Sun.otf');
}

// Muli
@font-face {
    font-family: 'Muli Light';
    src: url('../../fonts/Muli/Muli-Light.ttf');
}

@font-face {
    font-family: 'Muli';
    src: url('../../fonts/Muli/Muli-Regular.ttf');
}

@font-face {
    font-family: 'Muli Semibold';
    src: url('../../fonts/Muli/Muli-SemiBold.ttf');
}

@font-face {
    font-family: 'Muli Bold';
    src: url('../../fonts/Muli/Muli-Bold.ttf');
}

// Roboto
@font-face {
    font-family: 'Roboto Light';
    src: url('../../fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto/Roboto-Regular.ttf');
}